<style lang="sass" scoped>

</style>
<template lang="pug">
.bg-white.shadow-sm(style='min-height: calc(90vh); max-width: 780px')
  router-link.float-right(:to='`/property/${property_id}/views/message/sent`') 보낸 메시지 조회
  h4.p-4.mb-0.text-primary 메시지 템플릿 관리

  div(v-if='message_templates')
    span {{message_templates.length}}건
    button(type='button' @click='add()') 추가
    div.m-2(v-for='template in message_templates')
      //- pre {{template}}
      .border
        h5
          span {{template.name || '(이름없음)'}}
          strong.text-muted(v-show='template.disabled_at') (disabled)

          strong.ml-4 Target:
          span {{template.target}}

        button.btn.btn-secondary(type='button' @click='view(template)') view

    div.border.p-4(v-if='current.id')
      h5 조회
      form.form(@submit.prevent='submit_update()')
        strong name
        input(type='text' v-model='current.name')
        strong sms_text
        textarea.form-control(v-model='current.sms_text')
        strong email_subject
        textarea.form-control(v-model='current.email_subject')
        strong email_body
        textarea.form-control(v-model='current.email_body')

        h5 목표물
        span 특정 상태/태그에만 이 템플릿을 표시합니다.

        strong 상태
        b-form-radio-group(v-model='current.target.status' :options='status_options')

        button.btn.btn-primary(type='submit') 저장

        hr
        button(type='button' @click='deactivate(template)' v-show='!current.disabled_at') deactivate
        button(type='button' @click='activate(template)' v-show='current.disabled_at') reactivate


</template>

<script>

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
    status_flows() {
      return this.$store.state.status_flows
    },
    message_templates() {
      return this.$store.state.message_templates
    },
    message_templates_by_status() {
      return this.$store.state.message_templates_by_status
    },
  },
  async mounted() {
    await this.$store.dispatch('status flows', this.property_id)
    await this.$store.dispatch('message templates', this.property_id)

    this.status_options = ['전체'].concat(this.$store.state.status_flows).map(e => {
      return {
        text: e,
        value: e,
      }
    })

  },
  data() {
    return {
      current: {

      },
      status_options: [],
    }
  },
  methods: {
    view(row) {
      this.current = Object.assign({}, row)
      if (!this.current.target.status) {
        this.current.target.status = ''
      }
    },
    async add() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/message-templates`)
        if (r.data.message != 'ok') throw new Error(r.data.message || '추가실패')

        this.$modal.show('dialog', {
          title: '알림',
          text: '추가했습니다.',
        })


        await this.$store.dispatch('message templates', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit_update() {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/message-templates/${this.current.id}`, this.current)
        if (r.data.message != 'ok') throw new Error(r.data.message || '저장실패')

        this.$modal.show('dialog', {
          title: '알림',
          text: '저장했습니다.',
        })

        await this.$store.dispatch('message templates', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async activate() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/message-templates/${this.current.id}/activate`)
        if (r.data.message != 'ok') throw new Error(r.data.message || '저장실패')

        this.$modal.show('dialog', {
          title: '알림',
          text: '저장했습니다.',
        })

        await this.$store.dispatch('message templates', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async deactivate() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/message-templates/${this.current.id}/deactivate`)
        if (r.data.message != 'ok') throw new Error(r.data.message || '저장실패')

        this.$modal.show('dialog', {
          title: '알림',
          text: '저장했습니다.',
        })

        await this.$store.dispatch('message templates', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
  },
}
</script>
